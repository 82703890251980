module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6bb11bf46c11abc9b48edaa453e07e66"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"type":"MarkdownRemark","fields":[{"name":"title","indexed":true,"resolver":"frontmatter.title","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":true},{"name":"description","indexed":true,"resolver":"frontmatter.description","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":false},{"name":"content","indexed":true,"resolver":"rawMarkdownBody","attributes":{"encode":"balance","tokenize":"strict","filter":"en","threshold":6,"depth":3},"store":false},{"name":"url","indexed":false,"resolver":"fields.slug","store":true},{"name":"external","indexed":false,"resolver":"frontmatter.url","store":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"description","store":true},{"name":"tags","store":true},{"name":"content","store":true},{"name":"url","store":true}],"resolvers":{"MarkdownRemark":{}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
